import { useApi } from '@/composable/useApi'
const api = useApi()

let tokenDdi = ''

export const validateUser = async () => {
  const params = {
    schema: 'EcommPro_ValidateUser',
    userName: 'API',
    password: 'mV9PDDgQvpO7oX1U',
    passPhrase: 'AtUPY9EuOrFmey9eso5l0CKRXEh0R2vTqVRCoPKB7lzXqdXSiyRWifscmkPyaIy6',
    contactName: 'ecommprotestacct@ddisys.com'
  }

  try {
    const response = await api.client.post('/epic/ddi-validate-user', params)
    if (response && response.data) {
      tokenDdi = response.data.data.DDIResponse.token
    //   if (response.data.data.DDIResponse.isValid) {
    //     await fetchOrderDetailsDDI()
    //   }
    }
  } catch (error) {
    console.error('Error validating user:', error)
  }
}

export const submitOrder = async (orderable) => {
  const params = {
    schema: 'EcommProSubmitOrder',
    userName: 'API',
    password: 'mV9PDDgQvpO7oX1U',
    passPhrase: 'AtUPY9EuOrFmey9eso5l0CKRXEh0R2vTqVRCoPKB7lzXqdXSiyRWifscmkPyaIy6',
    token: tokenDdi,
    branch: '01',
    accountNumber: '0020102',
    user: [
      {
        userId: '0001',
        userName: '',
        firstName: 'eCommPro',
        lastName: 'TestAcct',
        email: orderable.customer.customer_id
      }
    ],
    purchaseOrder: 'PO #1',
    jobName: '',
    mobilePhone: '',
    specialInstructions: '',
    freightCharge: '0.0000',
    emailCC: orderable.customer.customer_id,
    shipMethod: 'flat',
    billAttention: 'eComm Test',
    shipAddress: {
      shipId: '00000001',
      shipCompanyName: '',
      shipAddress1: orderable.shipping_address.address_line1,
      shipAddress2: '',
      shipAddress3: '',
      shipCity: orderable.shipping_address.locality,
      shipState: orderable.shipping_address.administrative_area,
      shipPostCode: orderable.shipping_address.postal_code,
      shipCountry: 'US',
      shipPhone: '1234567890',
      shipFax: '',
      shipAttention: '',
      quoteRequest: 'Y',
      quoteNumber: '',
      convertQuote: 'Y',
      validateOnly: 'N'
    },
    lineItems: {
      itemData: orderable.items.map((item) => ({
        stockNum: 'LCP4040XP-US3-MC-LH',
        qty: item.quantity.toString(),
        uom: 'EA',
        price: (item.price.amount / 100).toString(),
        mfgNum: '',
        description: 'lorem ipsum text'
      }))
    },
    backOrderMethod: 'Ship backorders as available',
    orderType: 'ECOMM',
    orderTypeDescription: 'Web Order',
    emailTo: 'ecommprotestacct@ddisys.com',
    creditCardType: '1',
    creditCardNumber: '****9999',
    creditCardName: 'Steve Hechler',
    creditCardTransCode: 'S',
    creditCardApprovalCode: '1234567890',
    creditCardAuthCode: '123456'
  }

  try {
    const response = await api.client.post('/epic/ddi-submit-order', params)
    if (response && response.data) {
      console.log(response)
    }
  } catch (error) {
    console.error('Error submitting order:', error)
  }
}

export const fetchOrderDetailsDDI = async () => {
  const params = {
    schema: 'EcommProOrderDetail',
    userName: 'API',
    password: 'mV9PDDgQvpO7oX1U',
    passPhrase: 'AtUPY9EuOrFmey9eso5l0CKRXEh0R2vTqVRCoPKB7lzXqdXSiyRWifscmkPyaIy6',
    accountNumber: '0020102',
    token: tokenDdi,
    branch: '01',
    userId: '0001',
    orderNumber: '348644'
  }

  try {
    const response = await api.client.post('/epic/ddi-fetch-order', params)
    if (response && response.data) {
      console.log('orderFetch', response.data)
      return response.data
    }
  } catch (error) {
    console.error('Error fetching order details:', error)
  }
}
